
@keyframes Slide {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}

.ruleRow {
	transition: all 0.2s;
	
	td {
		padding: 0.3em;
		border-bottom: solid 1px black;
	}

	&--active:hover {
		cursor: pointer;
		background: rgba(227, 242, 253, 0.5);
	}
	
	&--disabled {
		background: linear-gradient(
			to right,
			rgba(209, 196, 233, 1) 0%,
			rgba(209, 196, 233, 1) 0%,
			rgba(209, 196, 233, 1) 30%,
			rgba(227, 242, 253, 1) 40%
		);
		background-size: 300% 300%;
		animation: Slide 1s ease 1;

		&:hover {
			cursor: not-allowed;
		}
	
		td:first-child {
			text-decoration: line-through;
		}
	}

	&--name {
		width: 50%;
		text-align: left;
	}
	
	&--score {
		width: 50%;
		text-align: right;
	}
}
