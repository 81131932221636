
.scoreTable {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  // width: 80%;
  background-color: white;
	padding: 1em;
	
	table {
		width: 100%;
		margin: 0 auto;
		min-width: 400px;
	}
	
	&--section {
		vertical-align: top;
		width: 100%;
	}

}


