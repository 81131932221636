/* Original Styles For Numbers, before icons added in */

.die-old {
  font-size: 25px;
  display: inline-block;
  padding: 0.5em 0.8em;
  font-family: monospace;
  font-weight: bold;
  background-color: black;
  color: white;
  margin-right: 1em;
}

/* THE NICER STYLES MADE FOR THE FONT-AWESOME ICONS... */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.die {
	color: white;
	// text-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);

	transition: all 0.3s ease;
	outline: none;
	padding: 0 0.1em;
}

.die--locked {
	opacity: 0.5;
}

.die:not(.die--locked) {
	filter: drop-shadow(0px 9px 15px rgba(0, 0, 0, 0.7));
}

.die:not(.die--locked):hover {
  cursor: pointer;
	opacity: 0.8;
}
.die--rolling {
  animation: spin 3s ease-out;
}

.die--rolling:hover,
.die[disabled] {
  cursor: not-allowed;
}


